type VoidFunction<T> = (value:T)=>void;
type VoidFunctionWithoutParams = ()=>void;
type PromiseVoidFunction = () => Promise<void>;


interface DataSchemaType {
    isLoading:boolean,
    isSubmitted:boolean,
    isAuthorizedOwner:boolean,
    setIsAuthorizedOwner:VoidFunction<boolean>,
    businessType:string,
    currentStep:number,
    setBusinessType:VoidFunction<string>,
    nextStep:VoidFunctionWithoutParams,
    previousStep:VoidFunctionWithoutParams,
    loanAmount:number,
    setLoanAmount:VoidFunction<number>,
    loanReason:string,
    setLoanReason:VoidFunction<string>,
    businessStartDate:string,
    setBusinessStartDate:VoidFunction<string>,
    annualRevenue:number,
    setAnnualRevenue:VoidFunction<number>,
    creditScore:string,
    setCreditScore:VoidFunction<string>,
    industry:string,
    setIndustry:VoidFunction<string>,
    businessZipCode:string,
    setBusinessZipCode:VoidFunction<string>,
    businessName:string,
    setBusinessName:VoidFunction<string>,
    personalDetails:PersonalDetails,
    setPersonalDetails:VoidFunction<PersonalDetails>,
    businessTaxID:string,
    setBusinessTaxID:VoidFunction<string>,
    signature:string,
    setSignature:VoidFunction<string>,
    password:string,
    setPassword:VoidFunction<string>,
    bankStatement:string,
    setBankStatement:VoidFunction<string>,
    dateOfBirth:string,
    setDateOfBirth:VoidFunction<string>,
    phone:string,
    setPhone:VoidFunction<string>,
    email:string,
    setEmail:VoidFunction<string>,
    ownershipDetails:OwnershipDetails,
    setOwnershipDetails:VoidFunction<OwnershipDetails>,
    ssn:string,
    setSSN:VoidFunction<string>,
    addressInfo:AddressInfo,
    setAddressInfo:VoidFunction<AddressInfo>,
    homeAddressInfo:AddressInfo,
    setHomeAddressInfo:VoidFunction<AddressInfo>,
    setCurrentStep:VoidFunction<number>,
    submitData:PromiseVoidFunction,
    completedStep:number[],
}

interface PersonalDetails{
    lastname: string,
    firstname: string,
}
interface AddressInfo{
    address: string,
    apt:string,
    city:string,
    state:string,
    postalCode:string,
}

interface OwnershipDetails{
        ownershipPercentage:number,
        isAuthorizedOwner:boolean,
}

export const DataSchema:DataSchemaType = {
        isLoading:false,
        isSubmitted:false,
        isAuthorizedOwner:false,
        setIsAuthorizedOwner:(value:boolean)=>{},
        businessType:"",
        currentStep:0,
        setBusinessType:(value:string)=>{},
        nextStep:()=>{},
        previousStep:()=>{},
        loanAmount:0,
        setLoanAmount:(amount:number)=>{},
        loanReason:"",
        setLoanReason:(reason:string)=>{},
        businessStartDate:new Date().toUTCString(),
        setBusinessStartDate:(date:string)=>{},
        annualRevenue:0,
        setAnnualRevenue:(amount:number)=>{},
        creditScore:"",
        setCreditScore:(score:string)=>{},
        industry:"",
        setIndustry:(value:string)=>{},
        businessZipCode:"",
        setBusinessZipCode:(value:string)=>{},
        businessName:"",
        setBusinessName:(value:string)=>{},
        personalDetails:{
            firstname:"",
            lastname:""
        },
        setPersonalDetails:(value:PersonalDetails)=>{},
        businessTaxID:"",
        setBusinessTaxID:(value:string)=>{},
        signature:"",
        setSignature:(value:string)=>{},
        password:"",
        setPassword:(value:string)=>{},
        bankStatement:"",
        setBankStatement:(value:string)=>{},
        dateOfBirth:new Date().toUTCString(),
        setDateOfBirth:(value:string)=>{},
        phone:"",
        setPhone:(value:string)=>{},
        email:"",
        setEmail:(value:string)=>{},
        ownershipDetails:{
            ownershipPercentage:0,
            isAuthorizedOwner:false,
        },
        setOwnershipDetails:(value:OwnershipDetails)=>{},
        ssn:"",
        setSSN:(value:string)=>{},
        addressInfo:{
            address: "",
            apt:"",
            city:"",
            state:"",
            postalCode:"",
        },
        setAddressInfo:(value:AddressInfo)=>{}, 
        homeAddressInfo:{
            address: "",
            apt:"",
            city:"",
            state:"",
            postalCode:"",
        },
        setHomeAddressInfo:(value:AddressInfo)=>{},
        setCurrentStep:(newStep:number)=>{},
        async submitData(){},
        completedStep:[],
        
}